<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="mt-2">
                <b-col cols="4">
                    <b-form-checkbox v-model="cb.en" name="check-button" size="lg">
                        {{$t('en')}}
                    </b-form-checkbox>
                </b-col>
                <b-col cols="8">
                    <ValidationProvider name="note.en" rules="min_value:0|max_value:100" v-slot="{ errors}">
                        <b-form-group>
                            <b-form-input v-model="note.en" :disabled="!cb.en" type="number"
                                           :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]"> {{ $t('error_min_0_max_100') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="4">
                    <b-form-checkbox v-model="cb.tr" name="check-button" size="lg">
                        {{$t('tr')}}
                    </b-form-checkbox>
                </b-col>
                <b-col cols="8">
                    <ValidationProvider name="note.tr" rules="min_value:0|max_value:100" v-slot="{ errors}">
                        <b-form-group>
                            <b-form-input v-model="note.tr" :disabled="!cb.tr" type="number"
                                           :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]"> {{ $t('error_min_0_max_100') }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
               
                <b-col cols="12" class="d-flex justify-content-center">
                    <b-button variant="primary" class="mt-0" @click="update">
                        {{ $t('save') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import UndergraduateTransferService from '@/services/UndergraduateTransferService'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number,
                default: null
            },
            formData: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                cb: {
                    en: true,
                    tr: false
                },
                note: {
                    en: null,
                    tr: null
                }
            }
        },
        created(){
           if('en' in  this.formData.prep_note) {
            this.note.en = this.formData.prep_note.en
            this.cb.en = true
           }
           if('tr' in this.formData.prep_note) {
            this.cb.tr = true
            this.note.tr = this.formData.prep_note.tr
        }   
        },
        methods: {
            async update() {
                if(this.checkPermission('undergraduatetransfer_approveforprepschool')){
                    const isValid = await this.$refs.formModalValidate.validate();
                    if(!isValid) {
                        return;
                    }
                    let formData = {
                        undergraduate_transfer_id: this.formId,
                    }

                    const dataNote = {}

                    if(this.cb.en) {
                        dataNote.en = this.note.en
                    }else {
                        delete dataNote.en
                    }
                    if(this.cb.tr) {
                        dataNote.tr = this.note.tr
                    }else {
                        delete dataNote.tr
                    }
                    formData.prep_note = dataNote

                    UndergraduateTransferService.prepSchoolApprove(formData)
                    .then(() => {
                        this.$emit('updated');
                    })
                    .catch((e) => {
                        this.showErrors(e)
                    })
                    
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },
        }
    }
</script>
