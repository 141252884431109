<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="proficiency_prep_note" rules="required" v-slot="{ errors}">
                        <b-form-group :label="$t('message')">
                            <b-form-textarea v-model="message"
                                                 :state="errors[0] ? false : null"
                                />
                            <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center">
                    <b-button variant="primary" class="mt-0" @click="send">
                        {{ $t('send') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import UndergraduateTransferService from '@/services/UndergraduateTransferService';

    export default {
        name: "BulkMessageForm",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number,
                default: null
            },
            formData: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                message: null,
            }
        },
        methods: {
            send() {
                if(this.checkPermission('undergraduatetransfer_approveforprepschool')){
                    let formData = {
                        message: this.message
                    }

                    UndergraduateTransferService.bulkMessage(formData)
                        .then((res) => {
                            this.$toast.success(this.$t("message_sent_student", { number: res.data.data }));
                            this.$emit('updated');
                        })
                        .catch((e) => {
                            this.showErrors(e)
                        })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            }
        }
    }
</script>
