<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('undergraduate_transfer_applications') + ' - ' + $t('prep_school')" :isColumns="true"
                    :actions="subMenu" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('undergraduate_transfer_applications') + ' - ' + $t('prep_school')"
                    :isColumns="true" :actions="subMenu" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                :exportExcel="true" @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox v-model="datatable.queryParams.filter.semester_id" />
                        </b-form-group>
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="undergraduate_transfer_statuses"
                                v-model="datatable.queryParams.filter.status" />
                        </b-form-group>
                        <b-form-group :label="$t('application_type')">
                            <parameter-selectbox code="undergraduate_transfer_types"
                                v-model="datatable.queryParams.filter.transfer_type" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input v-model="datatable.queryParams.filter.national_id"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('passport_number')">
                            <b-form-input v-model="datatable.queryParams.filter.passport_number"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6">
                        <legend tabindex="-1" class="col-form-label">
                            {{ $t('prefers') }}
                        </legend>
                        <div class="border rounded-sm p-3">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox v-model="datatable.queryParams.filter.preference_faculty_code"
                                    :level="'OL,L'" />
                            </b-form-group>
                            <b-form-group :label="$t('program')" class="mb-1">
                                <program-selectbox v-model="datatable.queryParams.filter.preference_program_code"
                                    :faculty_code="datatable.queryParams.filter.preference_faculty_code" />
                            </b-form-group>
                            <b-form-group :label="$t('result')">
                                <b-form-select v-model="datatable.queryParams.filter.result" :options="[{ text: $t('application_result.placed'), value: 'placed' },
                                { text: $t('application_result.not_placed'), value: 'not_placed' }]" />
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange" v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="showModal" :size="formSize" :footer="true" :onHideOnlyX="true"
                @bHideModalHeaderClose="showFormClose()">
                <template v-slot:CommonModalTitle>
                    {{ $t('show').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <show-form :formId="formId" :formData="formData" v-if="formProcess == 'show'" />
                </template>
                <template v-slot:CommonModalFooter>
                    <div class="d-flex ">
                        <b-button variant="primary" @click="updateForm(formData)">
                            {{ $t('update') }}
                        </b-button>
                    </div>
                </template>
            </CommonModal>
            <CommonModal ref="updateModal" :onHideOnlyX="true" @bHideModalHeaderClose="updateFormClose()">
                <template v-slot:CommonModalTitle>
                    {{ $t('update').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId" :formData="formData" v-if="formProcess == 'update'"
                        @updated="updateFormSuccess()" />
                </template>
            </CommonModal>
            <CommonModal ref="messageModal" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{ $t('bulk_message').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <message-form :formId="formId" :formData="formData" @updated="messageFormSuccess()" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Component
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';

import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'

// Services
import UndergraduateTransferService from '@/services/UndergraduateTransferService';

// Page
import ShowForm from './ShowForm';
import UpdateForm from './UpdateForm'
import MessageForm from './MessageForm'

// Other
import qs from 'qs';

export default {
    name: "UndergraduateTransferPrepSchool",
    components: {
        UpdateForm,
        MessageForm,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,

        FacultySelectbox,
        ProgramSelectbox,
        SemestersSelectbox,
        ParameterSelectbox,

        ShowForm
    },
    metaInfo() {
        return {
            title: this.$t('undergraduate_transfer_applications') + ' - ' + this.$t('prep_school')
        }
    },
    data() {
        return {
            formId: null,
            formSize: 'xxl',
            formData: {},
            formProcess: null,
            subMenu: [
                {
                    text: this.$t('bulk_message'),
                    class: 'ri-device-recover-line',
                    callback: () => {
                        this.$refs.messageModal.$refs.commonModal.show()
                    }
                },
            ],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'undergraduatetransfer_show',
                                callback: (row) => {
                                    this.showForm(row);
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                        hidden: true
                    },
                    {
                        label: this.$t('semester'),
                        field: 'semester',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.semester.academic_year + ' / ' + this.getSemester(row.semester.semester);
                        }
                    },
                    {
                        label: this.$t('national_id'),
                        field: 'national_id',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('passport_number'),
                        field: 'passport_number',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('name') + ' ' + this.$t('surname'),
                        field: 'name',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.name + ' ' + row.surname;
                        }
                    },
                    {
                        label: this.$t('preferences').toUpper(),
                        field: 'preferences',
                        sortable: false,
                        hidden: false,
                        formatFn: (value) => {
                            let html = '';
                            if (value.length > 0) {
                                html += '<ol class="pl-2 m-0">'
                                value.forEach((item) => {
                                    html += '<li>';
                                    if (item.faculty) html += this.getLocaleText(item.faculty, 'name') + '<br>';
                                    if (item.program) html += this.getLocaleText(item.program, 'name') + '<br>';
                                    html += '</li>';
                                })
                                html += '</ol>'
                            }

                            return html;
                        }
                    },
                    {
                        label: this.$t('status'),
                        field: 'status',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.getLocaleText(row, 'status_text');
                        }
                    },
                    {
                        label: this.$t('result').toUpper(),
                        field: 'result',
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            if (!row.result) return '-'
                            let variant = ['placed'].includes(row.result) ? 'badge-success'
                                : row.result == 'not_placed' ? 'badge-danger' : 'badge-warning'

                            return (
                                `<span class="badge ${variant}">${this.$t(`application_result.${row.result}`)}</span>`);
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        async exportExcel() {
            const config = {
                params: {
                    filter: {
                        ...this.datatable.queryParams.filter
                    }
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            const response = await UndergraduateTransferService.prepSchoolExcelReport(config)

            if (response?.status === 200) {
                this._downloadFile(response, `${this.$t('prep_school')}-${this.$t('undergraduate_transfer_applications')}-${this.$t('excel')}.xlsx`)
                this.$toast.success(`${this.$t('excel')} ${this.$t('downloaded')}`);
            }
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return UndergraduateTransferService.prepSchoolGetAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                })
        },

        // Form
        formClear() {
            this.formId = null
            this.formData = {}
            this.formProcess = null
            this.getRows();
        },

        // Show Form
        showForm(row) {
            this.formId = row.id
            this.formData = row
            this.formProcess = 'show'
            this.$refs.showModal.$refs.commonModal.show()
        },
        showFormClose() {
            this.formId = null
            this.formData = {}
            this.formProcess = null
        },

        // UpdateForm
        updateForm(row) {
            this.formId = row.id
            this.formData = row
            this.formProcess = 'update'
            this.$refs.showModal.$refs.commonModal.hide();
            this.$refs.updateModal.$refs.commonModal.show()
        },
        updateFormClose() {
            this.formId = null
            this.formData = {}
            this.formProcess = null
            this.getRows()
        },
        updateFormSuccess() {
            this.$refs.updateModal.$refs.commonModal.hide();
            this.getRows()
        },
        messageFormSuccess() {
            this.$refs.messageModal.$refs.commonModal.hide();
        }
    }
}
</script>
